import React from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
         ©{new Date().getFullYear()} Girar World
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
