const fetchPostApi = async (path, method, formData) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'client',
    body: JSON.stringify(formData),
  });

  const json = await response.json();
  await console.log(json, 'json2');
  return json;
};

export default fetchPostApi;
