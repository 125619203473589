import AuthForm, { STATE_LOGIN, STATE_REGISTER } from 'components/AuthForm';
import React from 'react';

class AuthPage extends React.Component {
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else if (authState === STATE_REGISTER) {
      this.props.history.push('/register');
    } else {
      this.props.history.push('/profile/setup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <AuthForm
        authState={this.props.authState}
        onChangeAuthState={this.handleAuthState}
        onLogoClick={this.handleLogoClick}
      />
    );
  }
}

export default AuthPage;
