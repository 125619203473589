import React, { useState, useEffect } from 'react';
import {
  STATE_LOGIN,
  STATE_REGISTER,
  STATE_UPDATE_PROFILE,
} from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import 'styles/reduction.scss';

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const EditUserPage = React.lazy(() => import('pages/EditUserPage'));
const EditProfilePage = React.lazy(() => import('pages/EditProfilePage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const RegistrationPage = React.lazy(() => import('pages/RegistrationPage'));
const ProfileViewPage = React.lazy(() => import('pages/ProfileViewPage'));
const BooksPage = React.lazy(() => import('pages/BooksPage'));
const SettingPage = React.lazy(() => import('pages/SettingPage'));
const ChaptersPage = React.lazy(() => import('pages/ChaptersPage'));
const UsersPage = React.lazy(() => import('pages/UsersPage'));
const AddBookPage = React.lazy(() => import('pages/AddBookPage'));
const EditBookPage = React.lazy(() => import('pages/EditBookPage'));
const AddChapterPage = React.lazy(() => import('pages/AddChapterPage'));
const EditChapterPage = React.lazy(() => import('pages/EditChapterPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

function AppMain(props) {
  const breakpoint = props.breakpoint;
  return (
    <BrowserRouter basename={getBasename()}>
      <GAListener>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => <AuthPage {...props} authState={STATE_LOGIN} />}
          />
          <LayoutRoute
            exact
            path="/register"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_REGISTER} />
            )}
          />
          <LayoutRoute
            exact
            path="/profile/setup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_UPDATE_PROFILE} />
            )}
          />
          <MainLayout breakpoint={breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/" component={DashboardPage} />
              <Route exact path="/login-modal" component={AuthModalPage} />
              <Route exact path="/buttons" component={ButtonPage} />
              <Route exact path="/cards" component={CardPage} />
              <Route exact path="/widgets" component={WidgetPage} />
              <Route exact path="/typography" component={TypographyPage} />
              <Route exact path="/alerts" component={AlertPage} />
              <Route exact path="/tables" component={TablePage} />
              <Route exact path="/badges" component={BadgePage} />
              <Route exact path="/button-groups" component={ButtonGroupPage} />
              <Route exact path="/dropdowns" component={DropdownPage} />
              <Route exact path="/progress" component={ProgressPage} />
              <Route exact path="/modals" component={ModalPage} />
              <Route exact path="/forms" component={FormPage} />
              <Route exact path="/input-groups" component={InputGroupPage} />
              <Route exact path="/charts" component={ChartPage} />
              <Route exact path="/edit-user" component={EditUserPage} />
              <Route exact path="/profile" component={ProfileViewPage} />
              <Route exact path="/edit-profile" component={EditProfilePage} />
              <Route exact path="/books" component={BooksPage} />
              <Route exact path="/settings" component={SettingPage} />
              <Route exact path="/chapters" component={ChaptersPage} />
              <Route exact path="/users" component={UsersPage} />
              <Route exact path="/add-book" component={AddBookPage} />
              <Route exact path="/edit-book" component={EditBookPage} />
              <Route exact path="/add-chapter" component={AddChapterPage} />
              <Route exact path="/edit-chapter" component={EditChapterPage} />
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </GAListener>
    </BrowserRouter>
  );
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(AppMain);
