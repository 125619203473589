import React from 'react';
import sidebarBgImage from '../../assets/img/bg/login-bg.png';
import SourceLink from 'components/SourceLink';
import { MdDashboard } from 'react-icons/md';
import { FaBook, FaUserCircle, Set } from 'react-icons/fa';
import { IoIosSettings } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

let navItems = [];
if (currentUser) {
  if (currentUser.role == 'Admin') {
    navItems = [
      { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
      { to: '/users', name: 'users', exact: false, Icon: FaUserCircle },
      { to: '/books', name: 'books', exact: false, Icon: FaBook },
      { to: '/settings', name: 'settings', exact: false, Icon: IoIosSettings },
    ];
  } else {
    navItems = [
      { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
      { to: '/users', name: 'users', exact: false, Icon: FaUserCircle },
      { to: '/books', name: 'books', exact: false, Icon: FaBook },
      { to: '/settings', name: 'settings', exact: false, Icon: IoIosSettings },
    ];
  }
} else {
  navItems = [
    { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
    { to: '/users', name: 'users', exact: false, Icon: FaUserCircle },
    { to: '/books', name: 'books', exact: false, Icon: FaBook },
    { to: '/settings', name: 'settings', exact: false, Icon: IoIosSettings },
  ];
}

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar className="text-center">
            <SourceLink className="navbar-brand d-block mr-auto ml-auto">
              <h1 className="text-white text-logo">Girar</h1>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
