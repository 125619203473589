import React, { useState, useEffect } from 'react';
import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import withBadge from 'hocs/withBadge';
import { useHistory } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import userImage from 'assets/img/users/300_4.jpg';
import { useCookies } from 'react-cookie';

import { MdClearAll, MdExitToApp, MdNotificationsActive } from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

function Header() {
  let history = useHistory();
  const [user, setUser] = useState([]);
  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);

  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  };

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  const [cookies, setCookies, removeCookies] = useCookies([
    'currentUser',
    'isLoggedIn',
  ]);

  useEffect(() => {
    const currentUser = cookies.currentUser;

    if (!currentUser) {
      history.push('/login');
    } else {
      if (currentUser) {
        const retrieveUser = async () => {
          const response = fetch(
            `${process.env.REACT_APP_API_URL}/user/` + currentUser,
            {
              method: 'GET', // *GET, POST, PUT, DELETE, etc.
              mode: 'cors', // no-cors, cors, *same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'omit', // include, *same-origin, omit
              headers: {
                //'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': true,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                'Access-Control-Allow-Headers':
                  'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
              },
              redirect: 'follow', // manual, *follow, error
              referrer: 'client', // no-referrer, *client
            },
          )
            .then(response => {
              return response.json();
            })
            .then(events => {
              return events.data;
            });
          return response;
        };

        const getUser = async () => {
          const selectedUser = await retrieveUser();
          setUser(selectedUser);
        };
        getUser();
      } else if (currentUser.user.status == 'Pending') {
        history.push('/profile/setup');
      } else {
        history.push('/login');
      }
    }
  }, []);

  const logOut = e => {
    sessionStorage.clear();
    removeCookies('isLoggedIn', '', { path: '/' });
    removeCookies('currentUser', '', { path: '/' });
    history.push('/login');
  };

  const profilePage = e => {
    history.push('/profile');
  };

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline color="primary" onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>

      <Nav navbar className={bem.e('nav-right')}>
        <NavItem>
          <NavLink id="Popover2">
            {user?.avatar ? (
              <Avatar
                onClick={toggleUserCardPopover}
                className="can-click"
                src={
                  user?.avatar > 100
                    ? user?.avatar
                    : `${process.env.REACT_APP_URL_BASE}${user?.avatar}`
                }
              />
            ) : (
              <Avatar
                onClick={toggleUserCardPopover}
                className="can-click"
                src={userImage}
              />
            )}
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={isOpenUserCardPopover}
            toggle={toggleUserCardPopover}
            target="Popover2"
            className="p-0 border-0"
            style={{ minWidth: 250 }}
          >
            <PopoverBody className="p-0 border-light avatar-popup">
              {user.avatar ? (
                <UserCard
                  title={user.name}
                  subtitle={user.email}
                  //text="Last updated 3 mins ago"
                  className="border-light"
                  avatar={
                    user.avatar > 100
                      ? user.avatar
                      : `${process.env.REACT_APP_URL_BASE}${user.avatar}`
                  }
                >
                  <ListGroup flush>
                    <ListGroupItem
                      onClick={profilePage}
                      tag="button"
                      action
                      className="border-light"
                    >
                      <FaUserAlt /> Profile
                    </ListGroupItem>

                    <ListGroupItem
                      onClick={logOut}
                      tag="button"
                      action
                      className="border-light"
                    >
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              ) : (
                <UserCard
                  title={user.name}
                  subtitle={user.email}
                  className="border-light"
                  avatar={userImage}
                >
                  <ListGroup flush>
                    <ListGroupItem
                      onClick={profilePage}
                      tag="button"
                      action
                      className="border-light"
                    >
                      <FaUserAlt /> Profile
                    </ListGroupItem>

                    <ListGroupItem
                      onClick={logOut}
                      tag="button"
                      action
                      className="border-light"
                    >
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              )}
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

export default Header;
